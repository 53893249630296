<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        How many mL of
        <number-value :value="conc1" unit="\text{M}" />
        <chemical-latex :content="molecule" />
        would be required to make a
        <number-value :value="vol2" unit="\text{mL}" />
        solution of
        <number-value :value="conc2" unit="\text{M}" />
        <chemical-latex :content="molecule + '?'" />
      </p>

      <calculation-input
        v-model="inputs.vol1"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question389',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        vol1: null,
      },
    };
  },
  computed: {
    vol2() {
      return this.taskState.getValueBySymbol('vol2').content;
    },
    conc1() {
      return this.taskState.getValueBySymbol('conc1').content;
    },
    conc2() {
      return this.taskState.getValueBySymbol('conc2').content;
    },
    molNumber() {
      return this.taskState.getValueBySymbol('molNumber').content;
    },
    molecule() {
      if (this.molNumber.value === 1) {
        return 'H2SO4';
      } else if (this.molNumber.value === 2) {
        return 'K2Cr2O7';
      } else if (this.molNumber.value === 3) {
        return 'HCl';
      } else {
        return 'Error';
      }
    },
  },
};
</script>
